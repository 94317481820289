import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Markdown from "react-markdown"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Footer from "../components/Footer"

import GreenArrow from "../images/green-arrow.svg"

const Services = ({ data }) => {
  const page = data.strapiIndustryParent

  return (
    <Layout>
      <Seo title="Industries" />

      <section className="industry-parent-hero">
        <div className="blue-line-container">
          <div className="container industry-parent-hero__inner">
            <h1 className="industry-parent-hero__title">{page.Title}</h1>
            <div className="industry-parent-hero__subtitle">
              <Markdown children={page.Description} />
            </div>
            <img src={GreenArrow} className="industry-parent-hero__arrow" />
          </div>
        </div>
        <img
          src={page.Image.url}
          alt={page.Image.alternativeText}
          className="industry-parent-hero__image"
        />
      </section>

      <section className="industry-parent-main">
        <div className="blue-line-container">
          <div className="container industry-parent-main__inner">
            <div className="content-container">
              <h2 className="indsutry-parent-main__title">
                {page.industryIntroTitle}
              </h2>
              <div className="indsutry-parent-main__text">
                <Markdown children={page.industryIntroText} />
              </div>
            </div>
            <div className="industry-parent-main__grid">
              {page.industries.map(industry => (
                <div className="industry-parent-main__item">
                  <Link
                    className="secondary-btn"
                    to={`/industries/${industry.slug}`}
                  >
                    <div className="btn-inner-container">
                      <img src={industry.Icon.url} className="icon" />
                      {industry.Title}
                    </div>
                  </Link>
                  <Markdown children={industry.ShortDescription} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiIndustryParent {
      Image {
        alternativeText
        url
      }
      Description
      Title
      industries {
        slug
        Title
        Icon {
          alternativeText
          url
        }
        ShortDescription
      }
      industryIntroText
      industryIntroTitle
      offsetImageBlock {
        Text
        Title
      }
    }
  }
`

export default Services
